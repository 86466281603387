import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import * as intlTelInput from 'intl-tel-input';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  @Input() isPopup: boolean = false;
  @Output() popupTriggered = new EventEmitter<void>();
  private apiUrl = environment.apiUrl;
  siteKey = environment.reCaptchaKey;
  loginRegisterTranslations: any = this.translate.instant('login_register');
  registerForm!: FormGroup;
  hidePassword: boolean = true;
  password: string = '';
  rememberMe: boolean = false;
  visible: boolean = false;
  isLoading: boolean = false;
  submitted: boolean = false;
  inputTel: any;
  input!: HTMLInputElement;
  inputPhoneError!: number;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      xid: [''],
      recaptcha: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.email, emailPatternValidator(),
          gmailEmailValidator(),
],
      ],
      phone: [''],
      password: ['', [Validators.required, passwordValidator()]],
    });

    this.input = document.querySelector('#phone') as HTMLInputElement;
    this.inputTel = intlTelInput(this.input, {
      initialCountry: 'fr',
      nationalMode: true,
      preferredCountries: ['fr', 'be', 'ch', 'lu'],
      utilsScript:
        'https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.14/build/js/utils.js',
    });

    'input countrychange'.split(' ').forEach((e) => {
      this.input.addEventListener(e, () => {
        if (this.input.value != '' && !this.inputTel.isValidNumber()) {
          this.inputPhoneError = this.inputTel.getValidationError();
          this.registerForm.controls['phone'].setErrors({
            incorrect: true,
          });

          this.input.closest('.iti')?.classList.remove('ng-valid');
          this.input.closest('.iti')?.classList.add('ng-invalid');
        } else {
          this.inputPhoneError = 0;
          this.input.closest('.iti')?.classList.remove('ng-invalid');
          this.input.closest('.iti')?.classList.add('ng-valid');
        }
      });
    });

    this.setXIDToken();
  }

  ngOnDestroy(): void {
    if (this.inputTel) {
      this.inputTel.destroy();
    }
  }

  triggerPopup() {
    this.popupTriggered.emit();
  }

  hasLowerCase(value: string): boolean {
    return /[a-z]/.test(value);
  }

  hasUpperCase(value: string): boolean {
    return /[A-Z]/.test(value);
  }

  hasSpecialChar(value: string): boolean {
    return /[!@#$%^&*(),.?":{}|<>]/.test(value);
  }

  hasDigit(value: string): boolean {
    return /\d/.test(value);
  }

  hasMinimumLength(value: string): boolean {
    return value.length >= 12;
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  loginWithGoogle(type: 'google' | 'facebook') {
    const url = `${this.apiUrl}/${type}`;
    this.isLoading = true;

    this.http.get<any>(url, { withCredentials: true }).subscribe(
      (data) => {
        if (data) {
          const popup = window.open(data, '_blank', 'width=500,height=600');

          const popupCheckInterval = setInterval(() => {
            if (popup && popup.closed) {
              clearInterval(popupCheckInterval);
              this.isLoading = false;
            }
          }, 1000);

          window.addEventListener('message', (event) => {
            if (event.origin !== this.apiUrl) {
              return;
            }

            const data = event.data;

            if (data && data.token) {
              this.login(data.token);
              this.isLoading = false;
            }

            if (popup) {
              popup.close();
              this.isLoading = false;
            }
          });
        }
      },
      (error) => {
        this.toastService.showToast({
          severity: 'error',
          summary: this.translate.instant('toast.error'),
          detail: this.loginRegisterTranslations.error_login.detail_google,
        });
        this.isLoading = false;
      },
    );
  }

  onSubmit(captchaResponse?: string) {
    this.submitted = true;
    this.isLoading = true;

    this.registerForm.get('recaptcha')?.setValue(captchaResponse);

    const formToken = this.registerForm.controls['xid'].value;
    const storedToken = localStorage.getItem('formToken');

    if (formToken !== storedToken || this.registerForm.value.recaptcha === '') {
      this.isLoading = false;
      return;
    }

    this.registerForm.value.phone = this.inputTel.getNumber();

    if (this.registerForm.valid) {
      this.http
        .post<any>(`${this.apiUrl}/api/register`, this.registerForm.value)
        .subscribe(
          (data) => {
            if (data && data.token) {
              this.login(data.token);
            } else {
              console.error(data);
            }
            this.isLoading = false;
          },
          (error) => {
            this.toastService.showToast({
              severity: 'error',
              summary: this.translate.instant('toast.error'),
              detail: this.loginRegisterTranslations.error_login.detail_form,
            });
            console.error('Erreur lors de la requête:', error);
            this.isLoading = false;
          },
        );
    } else {
      this.isLoading = false;
      Object.values(this.registerForm.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  async login(token: string) {
    this.authService.setAuthToken(token);
    localStorage.setItem('access_token', token);

    await this.userService.fetchUserData();
    if (!this.isPopup) {
      this.router.navigate(['/dashboard/account/disabled']);
    }
  }

  handleToken(captchaResponse: string, token: string): void {
    this.registerForm.get('recaptcha')?.setValue(captchaResponse);
  }

  generateXIDToken() {
    return Math.random().toString(36).substr(2);
  }

  executeReCaptcha(): void {
    this.recaptchaV3Service
      .execute('register')
      .subscribe((token) => this.onSubmit(token));
  }

  setXIDToken() {
    const token = this.generateXIDToken();
    this.registerForm.controls['xid'].setValue(token);
    localStorage.setItem('formToken', token);
  }
}

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]+/.test(value);
    const isLengthValid = value.length >= 12;

    const passwordValid =
      hasUpperCase &&
      hasLowerCase &&
      hasNumeric &&
      hasSpecialChar &&
      isLengthValid;

    return !passwordValid ? { passwordStrength: true } : null;
  };
}

export function emailPatternValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '') {
      return null;
    }

    const email = control.value;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(email);
    if (!isValid) {
      return { invalidEmailPattern: true };
    }
    return null;
  };
}

export function gmailEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value;
    if (email && email.endsWith('@gmail.com')) {
      return { gmailNotAllowed: true };
    }
    return null;
  };
}
