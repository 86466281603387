import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TrustpilotService } from 'src/app/shared/services/trustpilot.service';
import Swiper from 'swiper';
import moment from 'moment';
import 'moment/locale/fr';
import { HttpClient, HttpHeaders } from '@angular/common/http';

moment.locale('fr');

interface trustpilotItem {
  stars: number;
  title: string;
  text: string;
  createdAt: string;
  displayName: string;
  id: string;
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit, OnDestroy, AfterViewInit {
  trustpilotData!: trustpilotItem[];
  private swiperInstance?: Swiper;
  private swiperElement?: HTMLElement;
  @ViewChild('swiper') swiper!: ElementRef;

  constructor(
    private trustpilotService: TrustpilotService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.trustpilotData = this.trustpilotService.getContent(10);
    this.trustpilotData.forEach((item) => {
      const createdAtMoment = moment(item.createdAt);
      const relativeTime = createdAtMoment.fromNow();
      item.createdAt = relativeTime;
    });

    this.trustpilotData.forEach((item) => {
      if (item.text.length > 200) {
        item.text = item.text.substring(0, 200) + '...';
      }
    });
  }

  ngAfterViewInit(): void {
    this.waitSlide();
  }

  private waitSlide(): void {
    this.swiperElement = this.swiper.nativeElement as HTMLElement;

    const x = setInterval(() => {
      if (
        this.swiperElement &&
        this.swiperElement.querySelectorAll('.swiper-slide').length > 0
      ) {
        clearInterval(x);
        this.initialiserSwiper();
      }
    }, 100);
  }

  private initialiserSwiper(): void {
    if (
      this.swiperElement &&
      (!this.swiperInstance || this.swiperInstance.destroyed)
    ) {
      var self = this;
      this.swiperInstance = new Swiper(this.swiperElement, {
        rewind: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        width: 280,
        spaceBetween: 20,
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          resize() {
            self.swiperInstance?.update();
          },
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.swiperInstance && !this.swiperInstance.destroyed) {
      this.swiperInstance.destroy(true, true);
      this.swiperInstance = undefined;
      this.swiperElement = undefined;
    }
  }
}
