import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

interface MenuItem {
  title: string;
  routerLink?: string;
  url?: string;
  icon?: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isClient = false;
  isOneTime = false;
  questions: MenuItem[] = [];
  policies: MenuItem[] = [];
  videos: MenuItem[] = [];
  informations: MenuItem[] = [];
  socialNetworks: MenuItem[] = [];

  constructor(
    private subscriptionsService: SubscriptionsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptionsService.getSubscriptionsData().subscribe((data) => {
      if (data && data.length > 0) {
        this.isClient = true;
      }
    });

    this.router.events
    .pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    )
    .subscribe((event: NavigationEnd) => {
      this.isOneTime = event.url.includes('one-time');
    });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
