import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TrustpilotService {
  private trustpilotData = this.translate.instant('trustpilot.contents');

  constructor(private translate: TranslateService) {}

  getContent(count?: number) {
    this.trustpilotData.sort(() => Math.random() - 0.5);

    if (count) {
      return this.trustpilotData.slice(0, count);
    } else {
      return this.trustpilotData;
    }
  }
}
