// checkout.service.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCheckoutUrl(ndd: string, priceId: string): Observable<{ url: string }> {
    const gaCookie = this.getCookie('_ga');
    const gaZVMTCookie = this.getCookie('_ga_ZVMT658V6Z');

    console.log(gaCookie, gaZVMTCookie);

    const headers = new HttpHeaders({
      Cookie: `_ga=${gaCookie}; _ga_ZVMT658V6Z=${gaZVMTCookie}`,
    });

    return this.http.post<{ url: string }>(
      this.apiUrl + '/api/checkout',
      { ndd: ndd, priceId: priceId },
      { withCredentials: true, headers: headers },
    );
  }

  getCheckoutOneTimeUrl(ndd: string, index: number): Observable<{ url: string }> {
    const gaCookie = this.getCookie('_ga');
    const gaZVMTCookie = this.getCookie('_ga_ZVMT658V6Z');

    console.log(gaCookie, gaZVMTCookie);

    const headers = new HttpHeaders({
      Cookie: `_ga=${gaCookie}; _ga_ZVMT658V6Z=${gaZVMTCookie}`,
    });

    return this.http.post<{ url: string }>(
      this.apiUrl + '/api/checkout-onetime',
      { ndd: ndd, productIndex: index },
      { withCredentials: true, headers: headers },
    );
  }

  private getCookie(name: string): string {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return '';
  }
}
