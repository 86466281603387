// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-container {
  width: calc(100% - 20rem);
}
@media only screen and (max-width: 1199px) {
  .swiper-container {
    width: 100%;
  }
}

.swiper {
  padding-bottom: 30px;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--primary-color);
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--surface-100);
  transition: background 0.2s ease-in-out;
  bottom: 0;
  top: initial;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: var(--surface-200);
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1rem;
  font-weight: bold;
}
@media only screen and (max-width: 1199px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

:host ::ng-deep .swiper-pagination-bullet {
  width: 1rem;
  border-radius: 8px;
  background: white;
  height: 6px;
  transition: width 0.2s ease-in;
}
:host ::ng-deep .swiper-pagination-bullet-active {
  transition: width 0.2s ease-in;
  background: var(--primary-color);
  width: 5rem;
  border-radius: 8px;
  height: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/testimonials/testimonials.component.scss"],"names":[],"mappings":"AAMA;EACE,yBAAA;AALF;AADE;EAKF;IAII,WAAA;EAJF;AACF;;AAOA;EACE,oBAAA;AAJF;;AAOA;;EAEE,2BAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,8BAAA;EACA,uCAAA;EACA,SAAA;EACA,YAAA;AAJF;AAME;;EACE,8BAAA;AAHJ;AAME;;EACE,eAAA;EACA,iBAAA;AAHJ;AAhCE;EAiBF;;IAsBI,aAAA;EAFF;AACF;;AAME;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,8BAAA;AAHJ;AAME;EACE,8BAAA;EACA,gCAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AAJJ","sourcesContent":["@mixin md() {\r\n  @media only screen and (max-width: 1199px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n.swiper-container {\r\n  width: calc(100% - 20rem);\r\n\r\n  @include md() {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n.swiper {\r\n  padding-bottom: 30px;\r\n}\r\n\r\n.swiper-button-prev,\r\n.swiper-button-next {\r\n  color: var(--primary-color);\r\n  font-size: 2rem;\r\n  width: 2rem;\r\n  height: 2rem;\r\n  border-radius: 50%;\r\n  background: var(--surface-100);\r\n  transition: background 0.2s ease-in-out;\r\n  bottom: 0;\r\n  top: initial;\r\n\r\n  &:hover {\r\n    background: var(--surface-200);\r\n  }\r\n\r\n  &:after {\r\n    font-size: 1rem;\r\n    font-weight: bold;\r\n  }\r\n\r\n  @include md() {\r\n    display: none;\r\n  }\r\n}\r\n\r\n:host ::ng-deep {\r\n  .swiper-pagination-bullet {\r\n    width: 1rem;\r\n    border-radius: 8px;\r\n    background: white;\r\n    height: 6px;\r\n    transition: width 0.2s ease-in;\r\n  }\r\n\r\n  .swiper-pagination-bullet-active {\r\n    transition: width 0.2s ease-in;\r\n    background: var(--primary-color);\r\n    width: 5rem;\r\n    border-radius: 8px;\r\n    height: 6px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
